//
// The old styling for content carousels : still used by CarouselRow
// These carousels don't use swipers inbuilt Navigation
// instead nav buttons and rtl switch is handled in CarouselRow component
//

.carousel_new {
  position: relative;
  overflow: hidden;
  @include noselect();
  @include perspective(1000px);
  width: 100%;
}

.carousel_new .carousel_area {
  position: relative;
  overflow: hidden;
  margin-right: 1px;
}
.carousel_new {
  .nav_button {
    cursor: pointer;
    height: 70px;
    width: 70px;
    position: absolute;
    top: 32%;
    background: #ffffff;
    background-size: 200%;
    background-repeat: no-repeat;
    @include carouselArrowsInActive();
    &.nav_left {
      left: 0;
      @include backgroundimage('icons/arrows.svg');
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      background-position: 0 100%;
      transform: translateX(-100%);
    }
    &.nav_right {
      right: 0;
      @include backgroundimage('icons/arrows.svg');
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      background-position: 0 0%;
      transform: translateX(100%);
    }
    &.disabled {
      display: none;
    }
  }
}

html[dir='rtl'] {
  .carousel_new {
    .nav_button {
      &.nav_left {
        left: 0;
      }
      &.nav_right {
        right: 0;
      }
    }
  }
}

.carousel_new {
  .nav_button:hover {
    &.nav_left {
      background-position: 100% 100%;
      background-color: #9742b7;
    }
    &.nav_right {
      background-position: 100% 0%;
      background-color: #9742b7;
    }
  }
  &:hover .nav_button {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
    &.show.disabled {
      display: none;
    }
  }
  .nav_button.show {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
  }
}

// hack Fix carousel arrow overflow issue
// Removed becuase it was blocking part of the carousel
// .carousel_new .carousel_area:after {
//   content: '';
//   height: 100%;
//   width: 22px;
//   position: absolute;
//   right: 0;
//   top: 0;
//   overflow: hidden;
//   z-index: 100;
// }
.wrapper.b_purple .carousel_area:after {
  background-color: #983cb9;
}

.wrapper.b_yellow .carousel_area:after {
  background-color: #cedc28;
}

.wrapper.b_blue .carousel_area:after {
  background-color: #3791d3;
}

.wrapper.b_pink .carousel_area:after {
  background-color: #cc3366;
}

html[dir='rtl'] {
  .carousel_new .carousel_area {
    &:after {
      left: 0;
      right: auto;
    }
  }
}
