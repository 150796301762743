$image_path : "../images/";
$font_path : "../fonts/";

$color_facebook: #3B5998;
$color_twitter: #00B0ED;
$color_linkedin: #0065a0;

$color_blue: #43b8e7;
$color_yellow: #f9eb46;
$color_purple: #853a95;
$color_pink: #e62d91;

$bounce: cubic-bezier(0.47, 2.02, 0.31, -0.36);
$mildbounce: cubic-bezier(0.47, 3.32, 0.31, -0.36);
$superbounce: cubic-bezier(0.47, 5.02, 0.31, -0.36);

$padding: 20px;
$sectionMargin: 25px;
$iconHeight:60px;


$labelColor:rgba(0,0,0,0.15);

$color_blue: #3791d3;
$color_yellow: #cedc28;
$color_purple: #9742b7;
$color_pink: #cc3366;


.b_blue{
    background-color: $color_blue;
    color:#ffffff;
    border-radius: 20px;
    border: 10px solid #ffffff;
}

.b_yellow{
    background-color: $color_yellow;
    color: #ffffff;
    border-radius: 20px;
    border: 10px solid #ffffff;
}

.b_purple{
    background-color: $color_purple;
    color:#ffffff;
    border-radius: 20px;
    border: 10px solid #ffffff;
}

.b_pink{
    background-color: $color_pink;
    color:#ffffff;
    border-radius: 20px;
    border: 10px solid #ffffff;
}

.b_white{
    background-color: #ffffff;
}

/*MIXINS */
@mixin alignself {
  -ms-flex-item-align: center;
  align-self:          center;
}

@mixin backgroundimage($_image) {
  background-image:  url(#{$image_path}#{$_image});
  background-repeat: no-repeat;
}

@mixin noselect {
  -webkit-touch-callout: none;
  -webkit-user-select:   none;
  -khtml-user-select:    none;
  -moz-user-select:      none;
  -ms-user-select:       none;
  user-select:           none;
  cursor:                default;
}

@mixin select {
  -webkit-touch-callout: inherit;
  -webkit-user-select:   inherit;
  -khtml-user-select:    inherit;
  -moz-user-select:      inherit;
  -ms-user-select:       inherit;
  user-select:           inherit;
}

@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}

@mixin transform($extra:null) {
  -webkit-transform: $extra;
  -ms-transform:     $extra;
  transform:         $extra;
}

@mixin transformorigin($extra:null) {
  -webkit-transform-origin: $extra;
  -ms-transform-origin:     $extra;
  transform-origin:         $extra;
}

@mixin translatecenter($extra:null) {
  -webkit-transform: translate(-50%, -50%) $extra;
  -ms-transform:     translate(-50%, -50%) $extra;
  transform:         translate(-50%, -50%) $extra;
}

@mixin translatecenterx($extra:null) {
  -webkit-transform: translate(-50%, 0) $extra;
  -ms-transform:     translate(-50%, 0) $extra;
  transform:         translate(-50%, 0) $extra;
}

@mixin translatecentery($extra:null) {
  -webkit-transform: translate(0%, -50%) $extra;
  -ms-transform:     translate(0%, -50%) $extra;
  transform:         translate(0%, -50%) $extra;
}

@mixin translatenone {
  -webkit-transform: translate(0%, 0%);
  -ms-transform:     translate(0%, 0%);
  transform:         translate(0%, 0%);
}

@mixin transformstyle($val) {
  -webkit-transform-style: $val;
  -ms-transform-style:     $val;
  transform-style:         $val;
}

@mixin perspective($val) {
  -webkit-perspective: $val;
  -ms-perspective:     $val;
  perspective:         $val;
}

@mixin perspectiveorigin($val) {
  -webkit-perspective-origin: $val;
  -ms-perspective-origin:     $val;
  perspective-origin:         $val;
}

@mixin backface {
  -webkit-backface-visibility: hidden;
  backface-visibility:         hidden;
}

@mixin accelerate {
  -webkit-transform:           translate3d(0, 0, 0);
  transform:                   translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility:         hidden;
}

@mixin displayflex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex($count: 1) {
  -webkit-flex: $count; /* Safari 6.1+ */
  -ms-flex:     $count; /* IE 10 */
  flex:         $count;
}

@mixin center {
  text-align: center;
}

@mixin flexdirection($dir) {
  -webkit-flex-direction: $dir;
  -ms-flex-direction:     $dir;
  flex-direction:         $dir;
}

@mixin order($order) {
  -webkit-order: $order;
  -ms-order:     $order;
  order:         $order;
}

//@mixin calc($prop, $val) {
//  #{$prop}: calc($val);
//  #{$prop}: -webkit-calc($val);
//}

@mixin stroke($width, $color) {
  $width: $width + 0;
  $shadow: 0 0 0 transparent;
  $i: 0;
  $w: 1;

  @while ($i < $width) {
    $i: $i + 1;
    $j: 0;
    $w: $w + 2;

    @for $r from 1 through $w {
      @for $c from 1 through $w {
        $x: $c - math.ceil(math.div($w / 2));
        $y: $r - math.ceil(math.div($w / 2));

        $shadow: #{$shadow}, #{$x}px #{$y}px 0 $color;
      }
    }
  }

  text-shadow: $shadow;
}

@mixin animationfill($mode:forwards) {
  -webkit-animation-fill-mode: $mode; /* Safari 4.0 - 8.0 */
  animation-fill-mode:         $mode;
}

@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  -webkit-animation: $animations;
  animation:         $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@media(max-width: 767px) {
  .desktoponly {
    display: none !important;
  }
}

@media(min-width: 768px) {
  .mobileonly {
    display: none !important;
  }
}

@mixin carouselArrowsActive() {
  z-index: 99;
  transform: translate(0);
  -webkit-transition: background-color 0.3s, -webkit-transform 0.3s, z-index 0s;
  transition: background-color 0.3s, transform 0.3s, z-index 0s;
  opacity: 1;
}
@mixin carouselArrowsInActive() {
  z-index: -9999;
  opacity: 1;
  -webkit-transition: background-color 0.3s, -webkit-transform 0.3s, z-index 0s 0.3s;
  transition: background-color 0.3s, transform 0.3s, z-index 0s 0.3s;
}
