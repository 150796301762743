//
// The new styling for app screenshot and related items carousels
// These carousels use swipers inbuilt Navigation
//

.carousel_new {
  .swiper_btn {
    cursor: pointer;
    height: 70px;
    width: 70px;
    position: absolute;
    top: 32%;
    background: #ffffff;
    background-size: 200%;
    background-repeat: no-repeat;
    @include carouselArrowsInActive();
    &.swiper_button_prev {
      left: 0px;
      @include backgroundimage('icons/arrows.svg');
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      background-position: 0 100%;
      transform: translateX(-100%);
    }
    &.swiper_button_next {
      right: 0px;
      @include backgroundimage('icons/arrows.svg');
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      background-position: 0 0%;
      transform: translateX(100%);
    }
    &.disabled {
      display: none;
    }
  }
  .swiper_btn:hover {
    &.swiper_button_prev {
      background-position: 100% 100%;
      background-color: #9742b7;
    }
    &.swiper_button_next {
      background-position: 100% 0%;
      background-color: #9742b7;
    }
  }
  &:hover .swiper_btn {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
    &.show.disabled {
      display: none;
    }
  }
  .swiper_btn.show {
    @include carouselArrowsActive();
    &.disabled {
      display: none;
    }
  }
}

html[dir='rtl'] {
  .carousel_new {
    .swiper_btn {
      &.swiper_button_prev {
        right: 0;
        @include backgroundimage('icons/arrows.svg');
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        background-position: 0 100%;
        transform: translateX(-100%);
        left: auto;
      }
      &.swiper_button_next {
        left: 0;
        @include backgroundimage('icons/arrows.svg');
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        background-position: 0 0%;
        transform: translateX(100%);
        right: auto;
      }
    }
    .swiper_btn:hover {

    }
    &:hover .swiper_btn {
      @include carouselArrowsActive();
      &.disabled {
        display: none;
      }
      &.show.disabled {
        display: none;
      }
    }
    .swiper_btn.show {
      @include carouselArrowsActive();
      &.disabled {
        display: none;
      }
    }
  }
}
